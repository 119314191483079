<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const {
  locale: currentLocale,
  setLocale,
  locales,
  setLocaleCookie,
} = useI18n();

const props = defineProps<{
  class?: HTMLAttributes["class"];
  side?: "top" | "right" | "bottom" | "left";
}>();
</script>

<template>
  <UISelectRoot v-model="currentLocale" aria-label="Language">
    <UISelectTrigger
      aria-labelledby="labeldiv"
      :class="cn('shadow-0 bg-transparent outline-outline/40', props.class)"
    >
      <div class="flex items-center gap-2">
        <Icon name="ic:round-language" class="size-5 text-primary" />
        <UISelectValue placeholder="Select a language" id="labeldiv" />
        <!-- class="text-base" -->
      </div>
    </UISelectTrigger>
    <UISelectContent :side="side">
      <UISelectGroup>
        <UISelectItem
          v-for="locale in locales"
          :key="locale.code"
          :value="locale.code"
          indicator-position="start"
          @click="
            async () => {
              await setLocale(locale.code);
              setLocaleCookie(locale.code);
            }
          "
        >
          {{ locale.name }}
        </UISelectItem>
      </UISelectGroup>
    </UISelectContent>
  </UISelectRoot>
</template>
